import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
import { HomeGoopy } from '../components/goopy/HomeGoopy';

export const AppRouter = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeGoopy />} />
          <Route path="/viewer/:code" element={<HomeGoopy />} />
          <Route path="/goopy/view/*" element={<HomeGoopy />} />          
          <Route
            path="*"
            element={
              <main style={{ textAlign: "center", color: "white", fontSize: "3rem" }}>
                <p>404: Page Not Found</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    )
}
