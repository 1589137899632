import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDzpnQIFPMe6YtGMJ9sWNASSxRibOXTc7U",
    authDomain: "goopy-qa.firebaseapp.com",
    databaseURL: "https://goopy-qa.firebaseio.com",
    projectId: "goopy-qa",
    storageBucket: "goopy-qa.appspot.com",
    messagingSenderId: "495950958351",
    appId: "1:495950958351:web:3494ae073242facfd4b87a",
    measurementId: "G-CNKPM9Z7W0"
  },
  apiURL: 'https://qa-api.goopy.io/',
  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Miwicm9sZSI6MiwiaWF0IjoxNjM5NTIyNjA1fQ.s_eq-ob3464QeOhr5LlTO6e5Olhe1KxQuO3fbd2WVGU'
};

const appfire = initializeApp(environment.firebase);
const analyticsfire = getAnalytics(appfire);

export { appfire, analyticsfire };