import React from "react";
import { useEffect } from 'react';
import '../../css/StyleGoopy.css';
import AppHome from '../AppHome';
import { analyticsfire } from '../../environments/environment';
import { logEvent } from "firebase/analytics";
import { useParams, useLocation } from "react-router-dom";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export const HomeGoopy = () => {
  const { code } = useParams();
  const query = useQuery();

  useEffect(() => {
      document.title = 'Goopy Page';
      logEvent( analyticsfire, "goopy_visited");

      //favicon
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = 'https://goopy.io/admin/themes/goopy/files/uploads/favicon.png';
    }
  );

  return (
    <>
      <div className="ar-top">
         <img alt='logo' src="https://goopy-prod-webar.s3.amazonaws.com/resources/assets/top.png" />
      </div>

      <AppHome code={code} query={query} />
    </>
  );
}

