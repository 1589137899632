import { useState, useEffect } from 'react';
import Models from './Models';
import { environment } from '../environments/environment';


const apiCall = async (code) => {
  try {
    
    const response = await fetch(`${environment.apiURL}/experience/webar/${code}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${environment.token}`,
      },
    })
    const resp = await response.json().catch(err => {
      console.error(err)
      return false
    })
    return resp
  } catch (error) {
    console.log(error)
    return false
  }
}

function AppHome({ code = "", query = {} }) {
  // Data
  const modelsData = [
    {
      id: 1,
      name: 'Tania',
      link: 'https://modelviewer.dev/shared-assets/models/shishkebab.glb',
      linkios: '',
      audio: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'
    },
    {
      id: 2,
      name: 'Craig',
      link: 'https://modelviewer.dev/shared-assets/models/Astronaut.glb',
      linkios: '',
      audio: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-7.mp3'
    },
    {
      id: 3,
      name: 'Ben',
      link: 'https://modelviewer.dev/shared-assets/models/reflective-sphere.gltf',
      linkios: '',
      audio: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3'
    }
  ]
  const arPlacementFloor = query && query.arPlacementFloor ? query.arPlacementFloor : true
  const scaleAuto = query && query.scaleAuto ? query.scaleAuto : true
  const autoRotate = query && query.autoRotate ? query.autoRotate : true
  const progressBar = query && query.progressBar ? query.progressBar : true
  const arButton = query && query.arButton ? query.arButton : true


  const [modellinkSelected, setModelLinkSelected] = useState(modelsData[0].link);
  const [modellinkiosSelected, setModelLinkiosSelected] = useState(modelsData[0].linkios);
  const [modelaudioSelected, setModelAudioSelected] = useState(modelsData[0].audio);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    if (code) {

      const response = await apiCall(code);      
      
      if (!response.code) {
        setModelLinkSelected(response.src)
        setModelLinkiosSelected(response.iosSource)
        setModelAudioSelected(response.sound)
      }else {
        window.location.href = 'https://www.goopy.io/';
      }
    }
  }

  return (
    <>
      <Models
        modelarPlacementFloor={arPlacementFloor}
        modelscaleAuto={scaleAuto}
        modelautoRotate={autoRotate}
        modelprogressBar={progressBar}
        modelarArButton={arButton}
        modellinkSelected={modellinkSelected}
        modellinkiosSelected={modellinkiosSelected}
        modelaudioSelected={modelaudioSelected} />
      <section className="attribution">
        <span>
          <span>
          </span>
        </span>
      </section>
    </>
  );
}

export default AppHome;
