import React from 'react';
import Sounds from './Sounds';

const Models = props => (
    <>
        {
            props.modelaudioSelected &&
            <Sounds url={props.modelaudioSelected} />
        }
        <model-viewer
            src={encodeURI(props.modellinkSelected)} //Android/web
            ios-src={encodeURI(props.modellinkiosSelected)}  //IOS
            auto-rotate={props.modelautoRotate}
            camera-controls
            ar={props.modelarArButton}
            ar-scale={props.modelscaleAuto ? 'auto' : 'fixed'}
            ar-placement={props.modelarPlacementFloor ? 'floor' : 'wall'}
            style={{ width: '100%', height: '800px' }}
            onContextMenu="return false;"
        >
        </model-viewer>

    </>
)

export default Models
