import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Box from '@material-ui/core/Box';


const Sound = ({ url }) => {
  const [audios, setAudios] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    audios.pause()
    setAudios(new Audio(url));
  },
  [url]
  );

  useEffect(() => {
    if(playing){
      audios.volume = 1; 
      audios.play()
    }else{
      audios.volume = 0
    }
    },
    [playing]
  );

  return (
    <Box
    display="flex" 
    >
    <Box m="auto">
      <Button onClick={toggle} variant="contained" startIcon={playing ? <VolumeOffIcon /> : <VolumeUpIcon />}></Button>
    </Box>
  </Box>
  );
};

export default Sound;